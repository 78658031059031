import React from 'react';
import '../App.css';
import PropTypes from 'prop-types';

/**
 * Component that represents the elements within the collection list.
 *
 * A Collection Box contains the collection image and title.
 * @param {object} collection
 * @param {Function} setCollection
 * @return {Component}
 */
function CollectionBox({collection, setSelectedCollection}) {
  /**
   * Changes the view to the list of products available for the
   * selected collection.
   * @param {object} collection
   */
  function handleClick(collection) {
    setSelectedCollection(collection);
  }

  return (
    <div className="license-box-container"
      onClick={() => handleClick(collection)}>
      <div className="license-image-container">
        <img className="collection-image" src={collection.image}></img>
      </div>
      <p className="white-text">
        <b>{collection.title}</b>
      </p>
    </div>
  );
}

CollectionBox.propTypes = {
  collection: PropTypes.object,
  setSelectedCollection: PropTypes.func,
};

export default CollectionBox;
