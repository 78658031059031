import React, {Component, useState} from 'react';
import '../App.css';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Modal from '../common/Modal';
import {backendURL} from '../common/urls';
/**
 * Component that represents the Password recovery view.
 * @return {Component}
 */
function PasswordRecovery() {
  document.title = 'Recover Password';
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetButtomDisabled, setIsResetButtonDisabled] = useState(false);
  const passwordRecoveryURL = `${backendURL}/passwordRecovery`;
  // For waiting 5 minutes.
  const passwordResetTime = 5 * 60 * 1000;

  /**
   * Utilize password recovery endpoint.
   * @param {event} e
   */
  async function handlePasswordRecovery(e) {
    e.preventDefault();
    // A request is already in flight, do nothing.
    if (loading) {
      return;
    }
    // Input sanitation
    const [empty, message] = isInputEmpty();
    if (empty) {
      setErrorMessage(message);
      return;
    }

    setLoading(true);
    setIsResetButtonDisabled(true);

    try {
      await recoverPassword(email);
      setDisplayModal(true);
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }

    setTimeout(()=> setIsResetButtonDisabled(false), passwordResetTime);
  }



  /**
   * Attempts to send a password recovery email to the provided email if the account exists.
   * @param {string} email
   */
  async function recoverPassword(email) {
    await fetch(passwordRecoveryURL, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
    });
  }


  /**
   * Returns an array whose first element is a boolean value indicating
   * whether or not the input is empty and whose second element is a message
   * indicating which input field is empty.
   * @return {Array}
   */
  function isInputEmpty() {
    if (!email) {
      return [true, 'Email is empty'];
    }
    return [false, ''];
  }

  return (
    <div className="content-container">
      <div className="spinner-container" style={{display: loading ? 'block' : 'none'}}>
        <Spinner className="spinner" variant="inverted" size="big"/>
      </div>
      <Modal
        visible={displayModal}
        text={`A password recovery email has been sent to ${email} if this email is registered with us. Please wait for 5 minutes before retrying again.`}
        close={() => setDisplayModal(false)}
      />
      <h1 className="content-container-section-name">Send Password Reset Email</h1>
      <form onSubmit={(e) => handlePasswordRecovery(e)} className="credentials-form">
        <div>
          <label className="form-label">Email</label>
          <input
            className="form-field"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <input className="authentication-button" type="submit" value="Reset Password" disabled={isResetButtomDisabled}></input>
      </form>
      <p className="error-text">{errorMessage}</p>
    </div>
  );
}


export default PasswordRecovery;
