import React, {Component} from 'react';
import '../../App.css';
import BannerSection from './BannerSection';
import {
  generateQuickLinkContents,
  GetConnectedContents,
  ContactAWSThinkbox,
} from './BannerContents';
import {AWSCShortbread} from '@amzn/shortbread';
import {useHistory} from 'react-router-dom';

/**
 * Component for the banner at the bottom of the website.
 * @return {Component}
 */
function Banner() {
  const history = useHistory();
  const shortbread = AWSCShortbread({domain: '.thinkboxsoftware.com'});
  shortbread.checkForCookieConsent();

  /**
   * Sets the selected section to 'Terms of Use'
   */
  function selectTermsOfUse() {
    history.push('/terms-of-use');
  }

  /**
   * Opens the cookie preferences dialog.
   */
  function selectCookiePreferences() {
    shortbread.customizeCookies();
  }

  const QuickLinkContents = generateQuickLinkContents(selectTermsOfUse,
      selectCookiePreferences);

  const sections = [
    {name: 'Quick Links', contents: QuickLinkContents},
    {name: 'Get Connnected', contents: GetConnectedContents},
    {name: 'Contact AWS Thinkbox', contents: ContactAWSThinkbox},
  ];

  return (
    <div className="banner-holder">
      <div className="banner">
        <hr />
        <div className="banner-container">
          {sections.map((section, index) => {
            return <BannerSection
              name={section.name}
              contents={section.contents}
              key={index}
            />;
          })}
        </div>
        <hr />
      </div>
    </div>
  );
}
export default Banner;
