import React, {useContext, useState} from 'react';

const CartContext = React.createContext();

/**
 * Context provider whose value is an array containing objects in the cart
 * and a function for setting the objects in the cart.
 * @param {any} props
 * @return {Component} Context Provider
 */
function CartProvider(props) {
  const [cart, setCart] = useState([]);
  return <CartContext.Provider
    value={[cart, setCart]}
    {...props}/>;
}

/**
 * Returns an array whose first element is the cart and whose
 * second element is a function for setting the cart.
 *
 * Can only be used from a component that is within a CartProvider.
 * Example:
 * <CartProvider>
 *  <TestComponent/>
 * <CartProvider>
 *
 * @return {Array<object, Function>}
 */
function useCart() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}

/**
 * Returns the number of objects in a cart.
 * @param {Array<Object>} cart
 * @return {number}
 */
function itemsInCart(cart) {
  const itemsInCart = cart.reduce((total, item) => {
    return total + item.quantity;
  }, 0);
  return itemsInCart;
}

/**
 * Generates a list of line items from a cart.
 * Line items have a 'variantId' and 'quantity'.
 * @param {Array<Object>} cart
 * @return {Array<Object>} line items
 */
function generateLineItems(cart) {
  const lineItems = cart.map((item) => {
    const id = item.product.variants.edges[0].node.id;
    const qty = item.quantity;
    return {'variantId': id, 'quantity': qty};
  });
  return lineItems;
}

export {CartProvider, useCart, itemsInCart, generateLineItems};
