import React, {useState} from 'react';
import '../App.css';
import ProductList from './ProductList';
import ProductInfo from './ProductInfo';

/**
 * This component acts as a container for the ProductList and
 * ProductInfo components.
 * @param {function} setSelectedCollection sets the selected collection
 * @param {object} selectedCollection currently selected collection
 * @param {Array<object>} products Array of objects containing product
 * information
 * @return {Component}
 */
function ProductView({setSelectedCollection, selectedCollection, products}) {
  const [product, setProduct] = useState(null);
  if (product === null) {
    return (
      <ProductList
        selectedCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
        products={products}
        setProduct={setProduct}
      />
    );
  } else {
    return <ProductInfo product={product} setProduct={setProduct} />;
  }
}

export default ProductView;
