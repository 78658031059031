import React from 'react';
import '../App.css';

/**
 * Component for the Contact Us section of the website.
 * @return {Component}
 */
function ContactUs() {
  document.title = 'Contact Us';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">Contact Us</h1>
      <p className="content-container-text">
        If you can&apos;t find the information you need on this website, you can
        contact us.
        <ul className="content-container-list">
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://pages.awscloud.com/ThinkBoxContactUs.html">
              Contact AWS Thinkbox Sales
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/support/">
              Contact AWS Thinkbox Support
            </a>
          </li>
        </ul>
        Our operating hours are Monday to Friday 9am - 5pm (CT).
      </p>
    </div>
  );
}
export default ContactUs;
