import React from 'react';
import '../../App.css';

/**
 * Generates the HTML contents of the 'Quick Links' in the bottom banner.
 * @param {Function} selectTermsOfUse function to call when 'Terms of Use' link
 * is clicked
 * @param {Function} selectCookiePreferences function to call when
 * 'Cookie Preferences' link is clicked
 * @return {Component}
 */
export function generateQuickLinkContents(
    selectTermsOfUse,
    selectCookiePreferences,
) {
  /**
   * Returns the html
   * @return {Component}
   */
  function html() {
    return (
      <div className="link-container">
        <p><a className="clickable"
          onClick={() => selectTermsOfUse()}>Terms of Use</a>
        </p>
        <p><a target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/">AWS Thinkbox Website</a></p>
        <p><a className="clickable" onClick={() => selectCookiePreferences()}>
          Cookie Preferences
        </a></p>
      </div>
    );
  }
  return html();
};


export const GetConnectedContents = (
  <div className="link-container">
    <p><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/thinkboxsoftware/">Facebook</a></p>
    <p><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/thinkboxsoft">Twitter</a></p>
    <p><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/ThinkboxSoftware">Youtube</a></p>
  </div>
);

export const ContactAWSThinkbox = (
  <div className="link-container">
    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://pages.awscloud.com/ThinkBoxContactUs.html">
        Contact Sales
      </a>
    </p>
    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/support">Contact Support</a>
    </p>
    <p>Monday to Friday</p>
    <p>9am - 5pm (CT)</p>
  </div>
);

