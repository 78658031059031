import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Component for incrementing and decrementing a quantity.
 * Quantity cannot be decremented below 1 or above 100.
 * @param {number} quantity initial quantity
 * @param {Function} setQuantity function for setting the quantity
 * @return {Component}
 */
function QuantityCounter({quantity, setQuantity}) {
  /**
   * Increments the quantity
   */
  function increment() {
    setQuantity((q) => q < 100 ? q+1 : q);
  }

  /**
   * Decrements the quantity
   */
  function decrement() {
    setQuantity((q) => q > 1 ? q-1 : q);
  }

  return (
    <div className="quantity-counter-container">
      <div className="quantity-decrementer" onClick={() => decrement()}>-</div>
      <div className="quantity-counter">{quantity}</div>
      <div className="quantity-incrementer" onClick={() => increment()}>+</div>
    </div>
  );
}

QuantityCounter.propTypes = {
  quantity: PropTypes.number,
  setQuantity: PropTypes.func,
};

export default QuantityCounter;
