import React, {useState, useEffect} from 'react';
import '../App.css';
import {useAccount, isSignedIn} from './AccountContext';
import {useCart, itemsInCart} from '../common/CartContext';
import {Link} from 'react-router-dom';

/**
 * Component for the header at the top of the page.
 * @return {Component}
 */
function Header() {
  const {accountDetails} = useAccount();
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    setSignedIn(isSignedIn(accountDetails));
  }, [accountDetails]);

  const [cart] = useCart();
  const [itemCount, setItemCount] = useState(0);

  /**
   * Update the cart count whenever cart changes
   */
  useEffect(() => {
    const itemCount = itemsInCart(cart);
    setItemCount(itemCount);
  }, [cart]);

  return (
    <div className="header-container">
      <Link className="invisible-link" to={signedIn ? '/account' : '/sign-in'}>
        <h2 className="header-sign-in-create-account">
          {signedIn ? 'Account' : 'Sign in'}
        </h2>
      </Link>
      <h1 className="header-thinkbox-marketplace-text">
        AWS Thinkbox Marketplace
      </h1>
      <Link className="invisible-link" to='/cart'>
        <h2 className="header-cart">
          Cart({itemCount})
        </h2>
      </Link>
    </div>
  );
}


export default Header;
