import React from 'react';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 *  Component that represents a terms of agreement checkbox.
 * @param {boolean} checked whether or not check mark is selected
 * @param {Function} setChecked function for selecting the checkmark
 * @param {Function} path name of url path to open
 * @param {string} name what the terms and conditions are for
 * @return {Component}
 */
function TermsCheckbox({checked,
  setChecked,
  path,
  name}) {
  return (
    <Checkbox
      onChange={({detail}) => setChecked(detail.checked)}
      checked={checked}>
      <p className="white-text">I agree with the {' '}
        <Link className='clickable-link' to={{
          pathname: path,
          search: `?product=${name}`,
        }} target="_blank" rel="noopener noreferrer">
          terms and conditions
        </Link>
        {' '}for {name}
      </p>
    </Checkbox>
  );
}

TermsCheckbox.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  path: PropTypes.string,
  name: PropTypes.string,
};

export default TermsCheckbox;
