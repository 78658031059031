import React from 'react';
import '../App.css';
import ProductRequirements from './ProductRequirements';
import {Link} from 'react-router-dom';

/**
 * Commponent for the Requirements section of the website.
 *
 * @return {Component}
 */
function Requirements() {
  document.title = 'Requirements';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">
        Usage Based Licensing Requirements
      </h1>
      <p className="content-container-text">
        These are the general requirements for all Usage Based Licensing
        products purchased from the Thinkbox Marketplace. Please visit the{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/ubl-info">Usage Based Licensing</a>{' '}
        page on the Deadline website for more information. If you have any
        questions before you purchase, please{' '}
        <Link to="/contact-us" href="#" >
          contact our Sales Team
        </Link>
        !
        <br />
        <br />
        Here&apos;s what you need:
      </p>
      <ul className="requirements-list">
        <li>An Internet connection.</li>
        <li>
            The required version of Deadline (see product-specific requirements
            below).
        </li>
        <li>
          <b>Note that certain products could have other restrictions.</b> For
            example, Usage Based Licensing for some products is not available
            for rendering on premises
            (see <b>Product-specific requirements</b> below).
        </li>
      </ul>
      <div className="product-requirements-container">
        <h3 className="product-requirements-header">
        Product-specific requirements:
        </h3>
      </div>
      <ul className="requirements-list">
        {ProductRequirements.map((req) => req)}
      </ul>
    </div>
  );
}

export default Requirements;
