import React, {Component, useState, useEffect, dataSet} from 'react';
import './App.css';
import Header from './common/Header';
import NavigationBar from './common/NavigationBar';
import Notice from './common/Notice';
import ContentContainer from './common/ContentContainer';
import Banner from './common/BottomBanner/Banner';
import {CollectionProvider} from './common/CollectionContext';
import {CartProvider} from './common/CartContext';
import {AccountProvider} from './common/AccountContext';
import {backendURL} from './common/urls';


/**
 * Root React component of the application.
 * @return {Component}
 */
function App() {
   const [maintenance,maintenanceSet] = useState("false");
   const [maintenanceMessage,maintenanceMessageSet] = useState("");
   const [notice,noticeSet] = useState("false");
   const [noticeMessage,noticeMessageSet] = useState("");
   const [noticeWord, noticeWordSet] = useState([]);
   const [noticeLink, noticeLinkSet] = useState([]);
   useEffect(() => { async function fetchMaintenanceStatus(){
	   let response = await fetch(`${backendURL}/isMaintenance`, {method: 'GET', credentials: 'include'})
	   response = await response.json();
     if("error" in response){
       return;
     }
	   maintenanceSet(response['isMaintenance']);
	   maintenanceMessageSet(response['maintenanceMessage']);
     noticeSet(response['isNotice']);
     noticeMessageSet(response['noticeMessage'])
     noticeWordSet(response['noticeWord']);
     noticeLinkSet(response['noticeLink']);
	}
	fetchMaintenanceStatus()}, [])

  let sections = [
    {route: '/', name: 'Home'},
    {route: '/requirements', name: 'Requirements'},
    {route: '/usage-based-licensing', name: 'Usage Based Licensing'},
    {route: '/floating-licenses', name: 'Floating Licenses'},
    {route: '/contact-us', name: 'Contact Us'},
    {route: '/', name: 'AWS Thinkbox'},
    {route: '/faq', name: 'FAQ'},
  ];


  return (
    <>
      <div className="App">
        <AccountProvider>
          <CollectionProvider>
            <CartProvider>
              <Header/>
              <NavigationBar
                sections={sections}/>
              {notice == 'true'? <Notice text={noticeMessage} link={noticeLink} word={noticeWord}/>  : null }
              <ContentContainer
                maintenanceStatus={maintenance}
                maintenanceMessage={maintenanceMessage}/>
              <Banner/>
            </CartProvider>
          </CollectionProvider>
        </AccountProvider>
      </div>
    </>
  );
}

export default App;
