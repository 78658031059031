import React, {Component, useState} from 'react';
import '../App.css';
import PropertyType from 'prop-types';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import {backendURL} from '../common/urls';
import {useAccount,
  getAccountDetailsAndPendingOrders} from '../common/AccountContext';
import {Link, useHistory} from 'react-router-dom';
/**
 * Component that represents the Sign-in view.
 * @return {Component}
 */
function SignIn() {
  document.title = 'Sign in';
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const {setAccountDetails, setPendingOrders} = useAccount();
  const loginURL = `${backendURL}/login`;
  const history = useHistory();

  /**
   * Utilize login endpoint to sign in.
   * @param {event} e
   */
  async function handleSignIn(e) {
    e.preventDefault();
    // A request is already in flight, do nothing.
    if (loading) {
      return;
    }
    // Input sanitation
    const [empty, message] = isInputEmpty();
    if (empty) {
      setErrorMessage(message);
      return;
    }

    // attempt to Login and get account details
    setLoading(true);
    try {
      const loggedIn = await login(email, password);
      if (!loggedIn) {
        setErrorMessage('Invalid Credentials');
      }
      if (loggedIn) {
        const [accountDetails, pendingOrders] = await getAccountDetailsAndPendingOrders();
        setAccountDetails(accountDetails);
        setPendingOrders(pendingOrders);
        history.push('/account');
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Login using email and password. If successful, endpoint will set an
   * 'accessToken' cookie in browser that will be used to authenticate
   * subsequent requests. If unsuccessful, the function will return false
   * and call the setErrorMessage function with a message describing the error.
   * @param {string} email
   * @param {string} password
   * @param {Function} setErrorMessage
   * @return {object} Object containing users access token
   */
  async function login(email, password) {
    const response = await fetch(loginURL, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
      // we expect response to have 200 status code.
    if (response.status !== 200) {
      return false;
    }
    // Success
    return true;
  }


  /**
   * Returns an array whose first element is a boolean value indicating
   * whether or not the input is empty and whose second element is a message
   * indicating which input field is empty.
   * @return {Array}
   */
  function isInputEmpty() {
    if (!email) {
      return [true, 'Email is empty'];
    }
    if (!password) {
      return [true, 'Password is empty'];
    }
    return [false, ''];
  }

  return (
    <div className="content-container">
      <div className="spinner-container" style={{display: loading ? 'block' : 'none'}}>
        <Spinner className="spinner" variant="inverted" size="big"/>
      </div>
      <h1 className="content-container-section-name">Sign In</h1>
      <form onSubmit={(e) => handleSignIn(e)} className="credentials-form">
        <div>
          <label className="form-label">Email</label>
          <input
            className="form-field"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Password</label>
          <input
            className="form-field"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <input className="authentication-button" type="submit" value="Sign In"></input>
      </form>
      <p className="error-text">{errorMessage}</p>
      <p className="content-container-text">
        <Link to='/create-account' className="clickable">
          Don&apos;t have an account? Create one here
        </Link>
        <br/>
        <br/>
        <Link to='/password-recovery' className="clickable">
          Forgot your password? Reset it here
        </Link>
      </p>
    </div>
  );
}


export default SignIn;
