import React, {useState} from 'react';
import '../App.css';
import parsePhoneNumber from 'libphonenumber-js';
import Modal from '../common/Modal';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import {backendURL} from '../common/urls';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

/**
 * Component representing the Create Account view.
 * @return {Component}
 */
function CreateAccount() {
  document.title = 'Create Account';
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const signUpURL = `${backendURL}/signUp`;
  const history = useHistory();

  /**
   * Clears the contents of all text fields.
   */
  function clearFields() {
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  }

  /**
   * Create the users account.
   * @param {Event} e
   */
  async function handleCreateAccount(e) {
    e.preventDefault();
    // A request is already in flight, do nothing.
    if (loading) {
      return;
    }

    const validInput = inputIsValid();
    if (!validInput) {
      return;
    }

    setLoading(true);
    // Attempt to create an account
    try {
      const phoneNumber = parsePhoneNumber(phone, 'US');
      const accountInfo = {
        firstName: firstName,
        lastName: lastName,
        phone: phoneNumber ? phoneNumber.number : '',
        email: email,
        password: password,
      };
      await signUp(accountInfo);
      setDisplayModal(true);
      clearFields();
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Returns true of all account creation info is valid. Otherwise returns
   * false and sets a descriptive error message.
   * @return {boolean}
   */
  function inputIsValid() {
    if (firstName.length === 0) {
      setErrorMessage('First Name must not be empty');
      return false;
    }
    if (firstName.length > 20) {
      setErrorMessage('First Name must be less than 20 characters');
      return false;
    }
    if (lastName.length === 0) {
      setErrorMessage('Last Name must not be empty');
      return false;
    }
    if (lastName.length > 20) {
      setErrorMessage('Last Name must be less than 20 characters');
      return false;
    }
    if (email.length === 0) {
      setErrorMessage('Email is empty');
      return false;
    }
    if (email.length > 254) {
      setErrorMessage('Email is too long');
      return false;
    }
    
    const validPhoneNum = parsePhoneNumber(phone, 'US');
    if (!validPhoneNum && phone !== '') {
      setErrorMessage('Phone number is not in a recognised format.');
      return false;
    }
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return false;
    }
    if (password.length < 8) {
      setErrorMessage('Password must be at least 8 characters.');
      return false;
    }
    if (password.length > 128) {
      setErrorMessage('Password must be less than 128 characters.');
      return false;
    }
    if (! new RegExp('[a-z]').test(password)) {
      setErrorMessage('Password does not contain a lower case character.');
      return false;
    }
    if (! new RegExp('[A-Z]').test(password)) {
      setErrorMessage('Password does not contain an upper case character.');
      return false;
    }
    if (! new RegExp('[0-9]').test(password)) {
      setErrorMessage('Password does not contain a number.');
      return false;
    }
    if (! new RegExp('[^a-zA-Z0-9]').test(password)) {
      setErrorMessage('Password does not contain a special character.');
      return false;
    }
    if (new RegExp('[^ -~]').test(password)) {
      setErrorMessage('Password contains an invalid character.');
      return false;
    }
    // secondary checks
    if (password.includes(email)) {
      setErrorMessage('Password cannot contain your email.');
      return false;
    }
    if (password.includes(firstName)) {
      setErrorMessage('Password cannot contain your first name.');
      return false;
    }
    if (password.includes(lastName)) {
      setErrorMessage('Password cannot contain your last name.');
      return false;
    }
    if (password.includes(phone) && phone !== '') {
      setErrorMessage('Password cannot contain your phone number.');
      return false;
    }

    setErrorMessage('');
    return true;
  }

  /**
   * Utilize signUp endpoint to create an account.
   * Returns true if account was created successfully,
   * otherwise returns false.
   * @param {object} accountInfo
   */
  async function signUp(accountInfo) {
    const response = await fetch(signUpURL, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(accountInfo),
    });
    if (response.status != 200) {
      throw Error(`Unable to create account.
Ensure that the credentials you entered are valid and that you
have not already created an account with the same credentials.`);
    }
  };


  /**
   * Close the account creation message modal.
   */
  function closeModal() {
    setDisplayModal(false);
    history.push('/sign-in');
  }

  return (
    <div className="content-container">
      <div className="spinner-container" style={{display: loading ? 'block' : 'none'}}>
        <Spinner className="spinner" variant="inverted" size="big"/>
      </div>
      <Modal
        visible={displayModal}
        text={'Account created successfully!'}
        close={closeModal}
      />
      <h1 className="content-container-section-name">Create Account</h1>
      <p className="content-container-text">Your Thinkbox Customer Portal account will be created automatically after you purchase your first <Link to="/usage-based-licensing"
        className="clickable-link" >Usage Based Licensing</Link> product, and it will be tied directly to this Thinkbox Store account.
       Your Customer Portal will allow you to monitor your usage based licensing.
      <br/>
      <br/>
Note that render time from two separate Customer Portal accounts can not be combined. If you or your company already have an existing Customer Portal, and you would like to tie a new Store account to it, please
      <Link to="/contact-us" className="clickable-link"> contact Thinkbox Support</Link> and we will create your Store account for you.</p>
      <form
        className="credentials-form"
        onSubmit={(e) => handleCreateAccount(e)}
      >
        <div>
          <label className="form-label">First Name</label>
          <input
            className="form-field"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Last Name</label>
          <input
            className="form-field"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Phone Number (optional)</label>
          <input
            className="form-field"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Email</label>
          <input
            className="form-field"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Password</label>
          <input
            className="form-field"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Confirm Password</label>
          <input
            className="form-field"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <p className="content-container-text">
        By creating a Thinkbox Store account, you are agreeing to the
          <Link to="terms-of-use" className="clickable-link"> terms and conditions</Link>.
        </p>
        <input className="authentication-button" type="submit" value="Create Account" />
      </form>
      <p className="error-text">{errorMessage}</p>
      <p className="content-container-text">
        <Link to="/sign-in" className="clickable">
          Back to Sign In
        </Link>
      </p>
    </div>
  );
}


export default CreateAccount;
