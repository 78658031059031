import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../../App.css';

/**
 * Represents the vertical sections within the Banner.
 * Each section consists of a title and an element containing the contents.
 * @param {object} section object that contains the name and contents of a
 * section, where name is a string and contents is a react element.
 * @return {Component}
 */
function BannerSection({name, contents}) {
  return (
    <div>
      <h4 className="banner-section-name">{name}</h4>
      {contents}
    </div>
  );
}

BannerSection.propTypes = {
  name: PropTypes.string,
  contents: PropTypes.element,
};


export default BannerSection;
