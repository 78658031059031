import React from 'react';
import '../App.css';
import PropTypes from 'prop-types';


/**
 * Component that represents the items within the product list.
 *
 * This component is displayed as the product image, description,
 * and price.
 * @param {Object} product
 * @param {Function} setProduct
 * @return {Component}
 */
function ProductListItem({product, setProduct}) {
  /**
   * Selects a specific product, displaying the ProductView component.
   */
  function handleClick() {
    setProduct(product);
  }
  return (
    <div className="license-hours-item" onClick={() => handleClick()}>
      <img src={product.node.featuredImage.originalSrc}></img>
      <h3>{product.node.title}</h3>
      <p>${Number(product.node.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
    </div>
  );
}

ProductListItem.propTypes = {
  product: PropTypes.object,
  setProduct: PropTypes.func,
};

export default ProductListItem;
