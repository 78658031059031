import React from 'react';
import '../App.css';
import ProductListItem from './ProductListItem';
import PropTypes from 'prop-types';

/**
 * Component for displaying the list of products.
 * @param {Object} licenseData
 * @param {Function} setLicense
 * @param {Function} setProduct
 * @return {Component}
 */
function ProductList(
    {selectedCollection,
      setSelectedCollection,
      products,
      setProduct}) {
  /**
   * Displays the collection list.
   */
  function handleBackButtonClick() {
    setSelectedCollection(null);
  }

  return (
    <div className="license-view-container">
      <div className="license-name-header-container">
        <h1>{selectedCollection.title}</h1>
      </div>
      <p>{selectedCollection.description}</p>
      <div className="license-hours-container">
        {products.map((product, index) => {
          return (
            <ProductListItem
              product={product}
              setProduct={setProduct}
              key={index} />
          );
        })}
      </div>
      <div className="back-button-container">
        <button className="clickable" onClick={() => handleBackButtonClick()}>Back</button>
      </div>
    </div>
  );
}

ProductList.propTypes = {
  selectedCollection: PropTypes.object,
  setSelectedCollection: PropTypes.func,
  products: PropTypes.array,
  setProduct: PropTypes.func,
};

export default ProductList;
