import React, {Component, useEffect, useState} from 'react';
import {useAccount,
  getAccountDetailsAndPendingOrders} from '../common/AccountContext';
import {Redirect, useLocation} from 'react-router';

/**
 * Component that represents the Account page.
 * @return {Component}
 */
function Account() {
  document.title = 'Account';
  const location = useLocation();
  const {accountDetails, setAccountDetails, pendingOrders, setPendingOrders} = useAccount();
  const [orderHistory, setOrderHistory] = useState([]);
  const [pendingOrderList, setPendingOrderList] = useState([])
  useEffect(() => {
    /**
     * fetch account data and pending order list every time component remounts.
     */
    async function refreshAccountData() {
      const [details, orders] = await getAccountDetailsAndPendingOrders();
      setAccountDetails(details);
      const pastPendingOrders = findAggregatedPendingOrders(orders);
      setPendingOrders(pastPendingOrders);
    }
    if (location.state && location.state.refreshAccountData) {
      refreshAccountData();
    }
  }, [location]);

  /**
   * Edit out 'aggregate' orders from account orders.
   * We do this so that a customers pending orders and
   * order history clearly show combined orders.
   */
  useEffect(() => {
    if (accountDetails && accountDetails.orders) {
      const pastOrders = findAggregatedOrders(accountDetails.orders);
      setOrderHistory(pastOrders);
    }
    if (pendingOrders) {
      const pastPendingOrders = findAggregatedPendingOrders(pendingOrders);
      setPendingOrderList(pastPendingOrders);
    }
  }, [accountDetails, pendingOrders]);

  /**
   * Returns an array of orders. Orders that contain an "aggregate" tag are
   * edited to display under fulfillment that they are only an invoice.
   * Orders that are tagged "transformed to aggregate" have their payment
   * status changed to "BALANCE TRANSFERRED" to indicate that thier balance
   * was transfered to a end of month invoice.
   *
   * Orders containing an 'aggregate' tag were created by the backend
   * to make payment collection easier by combining a customers existing orders.
   * @param {Array<Object>} orders list of orders to filter
   * @return {Array<Object>}
   */
  function findAggregatedOrders(orders) {
    return orders.filter((order) => {
      if (order.tags.includes('aggregate'))
      {
        order.fulfillmentStatus = "END OF MONTH INVOICE";
      }
      if (order.tags.includes('transformed to aggregate') && order.paymentStatus === 'PAID')
      {
        order.paymentStatus = 'BALANCE TRANSFERRED';
      }
      return order;
    });
  }

  /**
   * Returns an array of pending orders. Orders that contain an "aggregate" tag are
   * edited to add a Note they are only an invoice.
   *
   * Pending prders containing an 'aggregate' tag were created by the backend
   * to make payment collection easier by combining a customers existing orders.
   * @param {Array<Object>} orders list of orders to filter
   * @return {Array<Object>}
   */
   function findAggregatedPendingOrders(orders) {
    return orders.filter((order) => {
      if (order.node.tags.includes('aggregate'))
      {
        order.node.note = "END OF MONTH INVOICE";
      }
      return order;
    });
  }

  /**
   * Logs out by setting the credential state to null
   * and navigating to the sign in view.
   */
  function logout() {
    setAccountDetails(null);
  }

  /**
   * Converts an iso format date time string to DD/MM/YYYY.
   * @param {string} iso
   * @return {string}
   */
  function isoToReadableDate(iso) {
    const date = Date.parse(iso);
    const readableDate = Intl.DateTimeFormat('en-US').format(date);
    return readableDate;
  }

  if (accountDetails && pendingOrders) {
    return <div className="content-container">
      <h1 className="content-container-section-name">My Account</h1>
      <p className="clickable-link" onClick={() => logout()}>Logout</p>
      <div className="account-info-container">
        <div>
          <h2>Account Details</h2>
          <div className="centered-flexbox">
            <div className="account-details-container">
              <h3>{accountDetails.firstName} {accountDetails.lastName}</h3>
              <p>{accountDetails.email}</p>
              <p>{accountDetails.phone}</p>
            </div>
          </div>
          <h2>Pending Orders</h2>
          <table className="pending-orders-table">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {pendingOrderList.map((item, key) => {
                const order = item.node;
                return (<tr key={key}>
                  <td>{order.name}</td>
                  <td>{isoToReadableDate(order.createdAt)}</td>
                  <td>${Number(order.totalPrice).toFixed(2)}</td>
                  <td>{order.note ? order.note : ""}</td>
                </tr>);
              })}
            </tbody>
          </table>
          <h2>Order History</h2>
          <table className="order-history-table">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Payment Status</th>
                <th>Fulfillment Status</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {orderHistory.map((order, key) => {
                return (<tr key={key}>
                  <td>{order.name}</td>
                  <td>{isoToReadableDate(order.createdAt)}</td>
                  <td>{order.paymentStatus}</td>
                  <td>{order.fulfillmentStatus}</td>
                  <td>${Number(order.amount).toFixed(2)}</td>
                </tr>);
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  } else {
    return <Redirect to="/sign-in"/>;
  }
}


export default Account;
