import React, {Component, useEffect, useState} from 'react';
import '../App.css';
import CollectionList from './CollectionList';
import ProductView from './ProductView';
import {useCollection} from '../common/CollectionContext';
import {collectionsURL, productsURL} from '../common/urls';

/**
 * Root component for the Usage Based Licensing page.
 *
 * This component acts as a container, displaying either the collection list
 * or specific information on a product.
 *
 * @param {Array<object>} collections array of objects containing collection
 * information
 * @param {object} producData object whose properties are collection ids
 * and values are a list of products belonging to that collection.
 * @return {Component}
 */
function UsageBasedLicensingView() {
  document.title = 'Usage Based Licensing';
  const [collections, setCollections] = useState();
  const [productData, setProductData] = useState();
  /**
   * prefetch product information
   */
  useEffect(() => {
    // Fetches collection data
    fetch(collectionsURL)
        .then((response) => response.json())
        .then((data) => setCollections(data));
    // Fetches product data
    fetch(productsURL)
        .then((response) => response.json())
        .then((data) => setProductData(data));
  }, []);

  const [selectedCollection, setSelectedCollection] = useCollection();
  if (!productData || selectedCollection === null) {
    return (
      <CollectionList
        setSelectedCollection={setSelectedCollection}
        collections={collections}
      />
    );
  } else {
    const products = productData[selectedCollection.id];
    return <ProductView
      selectedCollection={selectedCollection}
      setSelectedCollection={setSelectedCollection}
      products={products} />;
  }
}
export default UsageBasedLicensingView;
