import React, {Component} from 'react';
import '../App.css';
import banner from '../assets/aws_thinkbox_banner.jpg';
import {Link} from 'react-router-dom';

/**
 * Component for the Home section of the marketplace.
 * @return {Component}
 */
function Home() {
  document.title = 'Home';
  return (
    <div className="content-container">
      <img src={banner}></img>
      <h1 className="content-container-section-name">Important Notice</h1>
      <p className="content-container-text">
      The Thinkbox Marketplace is undergoing changes that impact how orders
      are submitted and processed. For existing customers, orders totaling 
      $5000 or less will be automatically fulfilled, and you will receive 
      an invoice for your order(s) at the end of the month. For new customers,
      your first order will not be fulfilled until you pay the corresponding 
      invoice, which you will receive within 2 business days of your order being placed.
      </p>
      <h3 className="workflow-text">Workflow for new customers:</h3>
      <ol className="content-container-text">
        <li><Link to="/create-account" className="clickable">Create</Link> a Thinkbox Marketplace account.</li>
        <li><Link to="/sign-in" className="clickable">Sign in.</Link></li>
        <li>Add Usage Based Licensing packages to your cart.</li>
        <li>Checkout.</li>
        <li>Wait to receive your invoice. This can take up to 2 business days.</li>
        <li>Pay your invoice.</li>
        <li>Receive confirmation email that the purchased render time has been added to your account. This can also take up to 2 business days.</li>
      </ol>
      <h3 className="workflow-text">Workflow for existing customers:</h3>
      <ol className="content-container-text">
        <li><Link to="/sign-in" className="clickable">Sign in.</Link></li>
        <li>Add Usage Based Licensing packages to your cart.</li>
        <li>Checkout.</li>
        <li>If the cost of your order, plus any unpaid orders for the current month is no more than $5000, no immediate payment is necessary, and your render time will be added to your account.
          <ul>
              <li>
             Note: If the cost of your order, plus any unpaid orders for the current month is greater than $5000, you will need to wait to receive your invoice. This can take up to 2 business days. After paying your invoice, you will receive a confirmation email that the purchased render time has been added to your account.
              </li>
          </ul>
        </li>
        <li>Receive an invoice for any outstanding orders at the end of the month.</li>
        <li>Pay your invoice.</li>
      </ol>
      <p className="content-container-text">Questions about the new process? See the <Link to="/faq" className="clickable">FAQ</Link></p>
      <h1 className="content-container-section-name">
        Welcome to the AWS Thinkbox Marketplace!
      </h1>
      <p className="content-container-text">
        On-demand Usage Based Licensing (UBL) is available for AWS Thinkbox
        Deadline 10 and later. The feature will work with both on-premises and
        cloud-based render nodes alongside existing permanent and temporary
        licenses. On-demand licensing can be purchased from the Thinkbox
        Marketplace 24/7, with minutes consumed only while the Deadline Worker
        application is rendering.
        <br />
        <br /> On-demand licensing for Krakatoa, as well as third-party
        applications like Autodesk 3ds Max, Maya and Arnold, Maxon C4D, Cnode
        for Clarisse, SideFX Houdini, NUKE, KATANA, Maxwell, Realflow, Redshift,
        V-Ray, and Yeti (with more to come in the future), can also be purchased
        from the Thinkbox Marketplace.
        <br />
        <br /> For more information, please visit the{' '}
        <Link to="/usage-based-licensing" className="clickable" >
          Usage Based Licensing
        </Link> page.
      </p>
    </div>
  );
}

export default Home;
