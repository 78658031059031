import React from 'react';
import '../App.css';

/**
 * If adding a product requirement, simply create an element variable like
 * the ones below and add it to the exported list at the bottom of the file.
 */
const threeDSMax =
<li>
3ds Max{' '}
  <ul>
    <li>Deadline 10.0 or later.</li>
    <li>
      3ds Max Usage Based Licensing is <b>NOT</b> available for rendering on
      premises.
    </li>
  </ul>
</li>;

const arnold =
<li>
Arnold{' '}
  <ul>
    <li>Deadline 10.0 or later.</li>
    <li>
    Arnold Usage Based Licensing is <b>NOT</b> available for
    rendering on premises.
    </li>
  </ul>
</li>;

const cinema4D =
<li>
Cinema4D{' '}
  <ul>
    <li>Deadline 10.1.2.2 or later.</li>
    <li>Supports Cinema 4D R21 or later.</li>
  </ul>
</li>;

const clarisse =
<li>
Clarisse{' '}
  <ul>
    <li>Deadline 10.0.19.2 or later.</li>
    <li>
    Clarisse Usage Based Licensing is <b>ONLY</b> available when rendering
    with CNode.
    </li>
  </ul>
</li>;

const deadline =
<li>
Deadline{' '}
  <ul>
    <li>Deadline 8.0.17.1 or later.</li>
  </ul>
</li>;

const houdini =
<li>
Houdini Engine{' '}
  <ul>
    <li>Deadline 10.1.0 or later.</li>
    <li>Supports Houdini 17.5 or later.</li>
  </ul>
</li>;

const katana =
<li>
Katana{' '}
  <ul>
    <li>Deadline 8.0.17.1 or later.</li>
  </ul>
</li>;

const keyshot =
<li>
Keyshot
  <ul>
    <li>Deadline 10.0.23.4 or later.</li>
  </ul>
</li>;

const krakatoa =
<li>
Krakatoa
  <ul>
    <li>Deadline 8.0.17.1 or later.</li>
  </ul>
</li>;

const mantra =
<li>
Mantra Renderer
  <ul>
    <li>Deadline 10.1.3 or later</li>
    <li>Supports Mantra 18.0.</li>
  </ul>
</li>;

const maya =
<li>
Maya
  <ul>
    <li>Deadline 10.0 or later.</li>
    <li>
    Maya Usage Based Licensing is <b>NOT</b> available for rendering
    on premises.
    </li>
  </ul>
</li>;

const nuke =
<li>
Nuke
  <ul>
    <li>Deadline 8.0.17.1 or later</li>
    <li>
      <b>ONLY</b> support Nuke Render Licenses.
    </li>
    <li>
    Does <b>NOT</b> support Nuke Interactive Licenses or Nuke
    plugins.
    </li>
  </ul>
</li>;

const redshift =
<li>
Redshift
  <ul>
    <li>Deadline 8.0.17.1 or later.</li>
    <li>
    Please ask Redshift support for a Floating license installer if
    you are a Node-locked user.
    </li>
  </ul>
</li>;

const vray =
<li>
V-Ray{' '}
  <ul>
    <li>
    On Windows and Linux, Deadline 8.0.17.1 or later. On Max OS X, a
    minimum of Deadline 9.0.9.1 or 10.0.6.3 is required.
    </li>
    <li>
    Only builds of V-Ray that were released after the first week of
    April 2016 are compatible.
    </li>
    <li>V-Ray for Cinema 4D is currently not compatible.</li>
  </ul>
</li>;

const yeti =
<li>
Yeti{' '}
  <ul>
    <li>
    On Windows, Deadline 8.0.17.1 or later. On Linux, a minimum of
    Deadline 9.0.9.1 or 10.0.6.3 is required.
    </li>
    <li>Only Yeti 2.0.25 or later is compatible.</li>
  </ul>
</li>;

export default [
  threeDSMax,
  arnold,
  cinema4D,
  clarisse,
  deadline,
  houdini,
  katana,
  keyshot,
  krakatoa,
  mantra,
  maya,
  nuke,
  redshift,
  vray,
  yeti];
