import React from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import {useCollection} from './CollectionContext';
import {useHistory} from 'react-router-dom';

/**
 * Component representing the navigation bar that contains links to each
 * section.
 * @param {Array<string>} sections names of the sections
 * @return {Component}
 */
function NavigationBar({sections}) {
  const [, setCollection] = useCollection();
  const history = useHistory();


  /**
   * Navigates to a specified route.
   * @param {string} route route to navigate to
   */
  function handleClick(route) {
    history.push(route);
    // Whenever a different section is selected, clear the selected license.
    // This allows the user to go back to the licenses list by clicking the
    // Usage Based Licensing tab in the navigation bar.
    setCollection(null);
  }
  return (
    <ul className="navigation-bar">
      {sections.map((section, index) => {
        if (section.name === 'AWS Thinkbox') {
          return (
            <li key={index}>
              {' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/">{section.name}</a>
            </li>
          );
        }
        return (
          <li key={index} onClick={() => handleClick(section.route)}>
            <a className="clickable">{section.name}</a>
          </li>
        );
      })}
    </ul>
  );
}
NavigationBar.propTypes = {
  sections: PropTypes.array,
};

export default NavigationBar;
