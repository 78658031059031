/* eslint max-len: ["error", { "ignoreStrings": true }]*/
import React from 'react';
import '../App.css';
import FloatingLicenses from '../FloatingLicenses/FloatingLicenses';
import UsageBasedLicensingView from '../UsageBasedLicensing/UsageBasedLicensingView';
import Requirements from '../Requirements/Requirements';
import ContactUs from '../ContactUs/ContactUs';
import Home from '../Home/Home';
import Cart from '../Cart/Cart';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import AutodeskEula from '../TermsOfUse/AutodeskEula';
import Maintenance from '../Maintenance/Maintenance';
import {
  Switch,
  Route,
} from 'react-router-dom';
import Account from '../Authentication/Account';
import CreateAccount from '../Authentication/CreateAccount';
import SignIn from '../Authentication/SignIn';
import FAQ from '../FAQ/FAQ';
import PasswordRecovery from '../Authentication/PasswordRecovery';
import ResetPassword from '../Authentication/ResetPassword';
import PropTypes from 'prop-types';
/**
 * This component acts as a container for displaying the
 * currently selected section.
 * Any new sections should be added to this component.
 * @return {Component}
 */
function ContentContainer({maintenanceStatus,maintenanceMessage}) {

  if(maintenanceStatus === "true"){
	  return (
	    <div className="content-flexbox">
	      <Maintenance message={maintenanceMessage}/>
	    </div>
	  );
  }

  return (
    <div className="content-flexbox">
      <Switch>
        <Route path="/maintenance">
          <Maintenance/>
        </Route>
        <Route path="/floating-licenses">
          <FloatingLicenses/>
        </Route>
        <Route path="/usage-based-licensing">
          <UsageBasedLicensingView/>
        </Route>
        <Route path="/requirements">
          <Requirements/>
        </Route>
        <Route path="/contact-us">
          <ContactUs/>
        </Route>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route path="/create-account">
          <CreateAccount/>
        </Route>
        <Route path="/account">
          <Account/>
        </Route>
        <Route path="/terms-of-use">
          <TermsOfUse/>
        </Route>
        <Route path="/autodesk-eula">
          <AutodeskEula/>
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/faq">
          <FAQ/>
        </Route>
        <Route path="/password-recovery">
          <PasswordRecovery/>
        </Route>
        <Route path="/reset-password">
          <ResetPassword/>
        </Route>
        <Route path="/">
          <Home/>
        </Route>
      </Switch>
    </div>

  );
}
ContentContainer.propTypes = {
  maintenance: PropTypes.string,
  maintenanceMessage: PropTypes.string,
};

export default ContentContainer;
