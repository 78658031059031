import React from 'react';
import PropTypes from 'prop-types';

/**
 * Modal component that pops up and displays some text.
 * @param {Boolean} visible Whether or not the modal is visible
 * @param {string} text text within the modal
 * @param {Function} close function that closes the modal
 * @return {Component}
 */
function Modal({visible, text, close}) {
  return (<div className="modal"
    style={{display: visible ? 'block': 'none'}}
    onClick={() => close()}
  >
    <div className="modal-content" >
      <button className="close">x</button>
      <p>{text}</p>
    </div>
  </div>
  );
}
Modal.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string,
  close: PropTypes.func,
};

export default Modal;
