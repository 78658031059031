import React from 'react';
import '../App.css';
import {backendURL} from '../common/urls';
import PropTypes from 'prop-types';

/**
 * Component for the Maintenance section of the website.
 * @return {Component}
 */
function Maintenance({message}) {

  document.title = 'Maintenance';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">Maintenance</h1>
      <p style={{color:'white'}}>{message}</p>
    </div>
  );
}
Maintenance.propTypes = {
  message: PropTypes.string,
};

export default Maintenance;
