import React, {useState, useEffect} from 'react';
import CartItem from './CartItem';
import TermsCheckbox from './TermsCheckbox';
import {useCart, generateLineItems} from '../common/CartContext';
import {useAccount, isSignedIn} from '../common/AccountContext';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Button from '@amzn/awsui-components-react/polaris/button';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {backendURL} from '../common/urls';
import {useHistory} from 'react-router-dom';

/**
 * Component for displaying the cart.
 * @return {Component}
 */
function Cart() {
  document.title = 'Cart';
  const history = useHistory();
  const [cart, setCart] = useCart();
  const [errorMessage, setErrorMessage] = useState('');
  const {accountDetails, setAccountDetails} = useAccount();
  const [loading, setLoading] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [displayOrderPlacedModal, setDisplayOrderPlacedModal] = useState(false);
  const [termsOfService, setTermsOfService] = useState({
  });
  const [orderSuccessMsg, setOrderSuccessMsg] = useState('');

  const autodeskProductNames = ['3ds Max', 'Maya', 'Arnold'];
  const draftOrderCreateUrl = `${backendURL}/createOrder`;

  const confirmationMessage = `By clicking confirm, you are agreeing to place 
 an order for $${Number(calculateSubtotal(cart)).toFixed(2)} and have our team
 reach out to you at ${accountDetails ? accountDetails.email : ''} to collect payment.`;

  const nonPreapprovedMsg = `Your order has been placed successfully! The 
Thinkbox Account Management team will send you an invoice at 
${accountDetails ? accountDetails.email : ''} within 2 business days. Your Usage
Based Licensing will be issued within 2 business days of paying your invoice.`;

  const preapprovedMsg = `Your order has been fulfilled and your Usage Based
Licensing has been issued. The Thinkbox Account Management team will send an invoice
to ${accountDetails ? accountDetails.email : ''} at the end of this month to collect
payment.`;

  useEffect(() => {
    if (cart && cart.length > 0) {
      const productsInCart = getAutodeskProductsInCart();
      // Construct object containing agreement names + agreement status
      const additionalTOS = {};
      for (const name of productsInCart) {
        additionalTOS[name] = false;
      }
      additionalTOS['the Thinkbox Marketplace'] = false;
      setTermsOfService(additionalTOS);
    } else {
      setTermsOfService({});
    }
  }, [cart]);

  /**
   * Returns the total price of the cart.
   * @param {Array<Object>} cart
   * @return {Number} price
   */
  function calculateSubtotal(cart) {
    const subtotal = cart.reduce((total, item) => {
      return total + itemPrice(item);
    }, 0);
    return subtotal;
  }

  /**
   * Returns the total price of an item (price/unit * quantity).
   * @param {Object} item
   * @return {Number} price
   */
  function itemPrice(item) {
    const price = parseFloat(item.product.priceRangeV2.maxVariantPrice.amount);
    const quantity = item.quantity;
    return price * quantity;
  }

  /**
   * Removes a specific item from the cart.
   * @param {String} id id of item to remove.
   */
  function removeItemFromCart(id) {
    setCart((cart) => {
      const result = cart.filter((item) => item.product.id !== id);
      return result;
    });
  }

  /**
   * Returns a Array containing the autodesk product names that are in the cart.
   * @return {Array} products
   */
  function getAutodeskProductsInCart() {
    const products = new Set();
    for (const item of cart) {
      for (const name of autodeskProductNames) {
        if (item.product.title.toLowerCase().includes(name.toLocaleLowerCase())) {
          products.add(name);
        }
      }
    }
    return Array.from(products);
  }


  /**
   * Returns a function that sets the quantity of a specific item in the cart.
   * @param {string} id id of item
   * @param {Function} cartSetter function for setting the cart
   * @return {Function} function that sets the cart of a specific item
   */
  function itemQuantitySetter(id, cartSetter) {
    /**
     * Sets the quantity of an item in the cart.
     * @param {number} quantity
     */
    function setQuantity(quantity) {
      cartSetter((cart) => {
        const updatedCart = cart.map((item) => {
          if (item.product.id === id) {
            const incrementedItem = {...item, quantity: quantity};
            return incrementedItem;
          }
          return item;
        });
        return updatedCart;
      });
    }
    return setQuantity;
  }

  /**
   * Displays the order confirmation dialog.
   */
  function handleCheckoutClicked() {
    const signedIn = isSignedIn(accountDetails);
    if (!signedIn) {
      setErrorMessage('You must be signed in to checkout.');
      return;
    }
    if (cart.length === 0) {
      setErrorMessage('Your cart is empty.');
      return;
    }
    if (!agreesToAllTerms()) {
      setErrorMessage('You must agree to all terms and conditions.');
      return;
    }

    // clear any previous error messages
    setErrorMessage('');

    setConfirmationVisible(true);
  }

  function signOut(){
    setAccountDetails(null);
    history.push('/sign-in');
  }

  /**
   * Complete checkout.
   */
  async function checkout() {
    const lineItems = generateLineItems(cart);
    setLoading(true);
    try {
      const response = await fetch(draftOrderCreateUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          lineItems: lineItems,
        }),
      });
      if (response.status === 200) {
        const body = await response.json();
        if (body.pre_approved) {
          setOrderSuccessMsg(preapprovedMsg);
        } else {
          setOrderSuccessMsg(nonPreapprovedMsg);
        }
        setDisplayOrderPlacedModal(true);
        setCart([]);
      } 
      // 403 means request is Forbidden - occurs when authorizer returns false
      // 401 means request Unauthorized - occurs if request is denied before reaching authorizer 
      else if (response.status === 403 || response.status == 401) {
        signOut();
      }
      else {
        setErrorMessage('Failed to place your order. Please try again later.');
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
      setConfirmationVisible(false);
    }
  }

  /**
   * Return the url path of the terms of service for a product.
   * @param {string} product
   * @return {string} path name
   */
  function getTOSPathName(product) {
    if (autodeskProductNames.includes(product)) {
      return 'autodesk-eula';
    } else {
      return 'terms-of-use';
    }
  }

  /**
   * Returns whether or not all terms of service boxes are checked.
   * @return {boolean}
   */
  function agreesToAllTerms() {
    for (const prop in termsOfService) {
      if (!termsOfService[prop]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Closes the order placed dialog and navigates to the account page,
   * refreshing account data so that the newly placed order is displayed.
   */
  function handleOrderPlacedDismissal() {
    setDisplayOrderPlacedModal(false);
    history.push('/account', {refreshAccountData: true});
  }


  return (
    <div className="content-container">
      <Modal
        visible={confirmationVisible}
        onDismiss={() => setConfirmationVisible(false)}
        size="medium"
        header="Confirm Order"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link"
                onClick={() => setConfirmationVisible(false)}>Cancel</Button>
              <Button loading={loading ? true : false}
                variant="primary"
                onClick={() => checkout()}>Confirm</Button>
            </SpaceBetween>
          </Box>
        }
      >
        {confirmationMessage}
      </Modal>

      <Modal
        visible={displayOrderPlacedModal}
        onDismiss={() => handleOrderPlacedDismissal()}
        size="medium"
        header="Order Placed"
      >
        {orderSuccessMsg}
      </Modal>

      <div className="spinner-container" style={{display: loading ? 'block' : 'none'}}>
        <Spinner className="spinner" variant="inverted" size="big"/>
      </div>
      <div className="modal">
        <div></div>
      </div>
      <h1 className="content-container-section-name">Cart</h1>
      <div className="cart-labels">
        <div>Minutes</div>
        <div>Quantity</div>
        <div>Total</div>
      </div>
      <div className="cart-item-list">
        {cart.map((item, index) => {
          return (
            <CartItem
              item={item}
              key={index}
              setItemQuantity={itemQuantitySetter(item.product.id, setCart)}
              removeItem={removeItemFromCart}/>
          );
        })}
      </div>
      <div className="cart-sub-total">
        <h2>
          Subtotal: ${Number(calculateSubtotal(cart)).toFixed(2)}
        </h2>
      </div>
      <div>
        <p className="white-text">For items priced zero dollars in your Cart, we recommend you "Checkout" items separately from non-zero priced items in order to have access to the usage based licensing minutes immediately.</p>
        <br></br>
      </div>
      <div>
        {Object.keys(termsOfService).map((name, key) => {
          return (<TermsCheckbox
            key={key}
            checked={termsOfService[name]}
            setChecked={() => setTermsOfService({...termsOfService, [name]: !termsOfService[name]})}
            path={getTOSPathName(name)}
            name={name}
          />);
        })}

      </div>
      <button className="orange-button"
        onClick={() => handleCheckoutClicked()}>Checkout</button>
      <p className="error-text">{errorMessage}</p>
    </div>
  );
}


export default Cart;
