import React from 'react';
import '../App.css';

/**
 * Component for the FAQ section of the website.
 * @return {Component}
 */
function FAQ() {
  const questionsAndAnswers = [
    {question: 'I\'m seeing "END OF MONTH INVOICE", and "BALANCE TRANSFERRED" under my order details. What do these mean?', answer: 'If you have placed any pre-approved orders in the past month, those orders will be collected into a single invoice and sent to you at the end of the month. These end of month invoice orders will be marked with “END OF MONTH INVOICE”, and the pre-approved orders will be marked with “BALANCE TRANSFERRED”.'},
    {question: 'I just placed an order but I have not received my invoice.', answer: 'It can take up to 2 business days before you receive your invoice. Thank you for your patience.'},
    {question: 'I paid my invoice but the Usage Based render time has not been added to my account.', answer: 'It can take up to 2 business days to confirm payment and add the render time to your account.'},
    {question: 'I placed an order that was automatically fulfilled without payment. When will I receive my invoice?', answer: 'We will send you an single invoice for any outstanding orders at the end of each month.'},
    {question: 'I placed multiple orders that were automatically fulfilled. Will I receive an invoice for each order?', answer: 'No. We will combine all of your outstanding orders into a single invoice that is sent at the end of the month.'}];
  document.title = 'FAQ';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">FAQ</h1>
      {questionsAndAnswers.map((faq, index) => {
        return (
          <div className="faq-container-text" key={index}>
            <h2>Q. {faq.question}</h2>
            <p>A. {faq.answer}</p>
          </div>
        );
      })}
    </div>
  );
}

export default FAQ;
