/* eslint max-len: ["error", { "ignorePattern": "*" }]*/
import React from 'react';

/**
 * Component for the Terms of Use page.
 * @return {Component}
 */
function TermsOfUse() {
  document.title = 'Terms of Use'
  return (
      <div className="terms-of-use">
        <h1>Thinkbox Marketplace Terms of Use</h1>
        <p><strong>CAUTION: CAREFULLY READ THE TERMS OF THIS AGREEMENT BEFORE ACCEPTING AND/OR BEFORE REGISTERING FOR THE THINKBOX MARKETPLACE. THE FOLLOWING ARE THE TERMS AND CONDITIONS OF YOUR USE OF THE THINKBOX MARKETPLACE AND THINKBOX PORTAL. IF YOU OR YOUR AGENT INDICATE YOUR AGREEMENT WITH THESE TERMS AND CONDITIONS, PURCHASE SOFTWARE FROM THE THINKBOX MARKETPLACE, OR USE THE THINKBOX MARKETPLACE OR THINKBOX PORTAL, YOU ACCEPT ALL OF THE TERMS OF THIS AGREEMENT AND AGREE TO BE BOUND BY THIS AGREEMENT. IF THE TERMS OF THIS AGREEMENT ARE NOT ACCEPTABLE TO YOU, (1) DO NOT CLICK THE CHECKBOX INDICATING YOUR AGREEMENT WITH THESE TERMS AND CONDITIONS; (2) DO NOT PURCHASE SOFTWARE FROM THE THINKBOX MARKETPLACE; (3) YOU MAY NOT USE THE THINKBOX MARKETPLACE OR THE THINKBOX PORTAL; AND (4) CONTACT THINKBOX SOFTWARE INC. (“THINKBOX”) IMMEDIATELY AT THE NUMBER LISTED BELOW.</strong></p>
        <p><strong>PROVIDED THAT YOU OR YOUR AGENT HAS ACCEPTED AND AGREED TO THE TERMS OF THIS AGREEMENT, THINKBOX GRANTS YOU OR YOUR AGENT (“CUSTOMER”) USE OF THE THINKBOX MARKETPLACE AND THE THINKBOX PORTAL AS INDICATED BELOW:</strong></p>
        <h3>1. Definitions.</h3>
        <p>“Personal Information” means information about an identifiable individual and for greater certainty, does not include information that has been redacted, edited, anonymized, aggregated or revised such that it is no longer identifiable to an individual.</p>
        <p>“Intellectual Property Rights” includes all patents, inventions, trade-mark, service marks, registered designs, integrated circuit topographies, including applications for any of the foregoing, as well as copyrights, design rights, know-how, confidential information, trade secrets and any similar rights in Canada and in any other country.</p>
        <p>“Software” means the software products accessed or used by the Customer via the Thinkbox Portal, whether Thinkbox products or third party products.</p>
        <p>“Task” means the unit of work that the Customer uses the Software to complete.</p>
        <p>“Thinkbox Portal” means the web portal through which the Customer is able to access or use the Software.</p>
        <p>“Thinkbox Marketplace” means the interface through which the Customer creates an account to use the Thinkbox Portal and purchases Prepaid Usage.</p>
        <p>“Usage” means use of Software via the Thinkbox Portal to complete a Task, measured in respect of the processing time incurred by the Customer.</p>
        <h3>2. Account.</h3>
        <p>(a) Customer acknowledges that it has provided true, accurate, current and complete information as requested in registering an account to use the Thinkbox Marketplace and Thinkbox Portal (the “Account”), and that Customer shall maintain and update this registration information to keep it true, accurate and complete.</p>
        <p>(b) Customer acknowledges and agrees that the Account may not be transferred to any other person or entity, and the Customer shall not allow or enable others to access the Account.</p>
        <p>(c) Customer is solely responsible for maintaining the confidentiality of the Account, including the password, and accepts sole responsibility for all activities that occur under the Account. Customer shall notify Thinkbox immediately of any security breach of the Account.</p>
        <p>(d) By creating an Account, Customer authorizes Thinkbox to charge the Customer’s credit card for (or use other forms of payment methods accepted by Thinkbox to obtain payment of) the Fees (as hereinafter defined) for Usage ordered.</p>
        <p>(e) Thinkbox reserves the right to disable or block the Account without notice.</p>
        <p>(f) Customer acknowledges that Personal Information is required in order to create the Account. The Customer consents to the use of the Customer’s email address to contact the Customer regarding Usage and for potential marketing opportunities.</p>
        <h3>3. Restrictions.</h3>
        <p>Unless enforcement is prohibited by applicable law, Customer may not access or use the Thinkbox Portal in any country other than the country in which the Customer is based at the date of purchase (the “Home Country”). Notwithstanding the foregoing, the Customer may use or access the Thinkbox Portal outside the Home Country if traveling or working outside the Home Country on a temporary basis, provided that such use does not exceed 70 days in the aggregate in any rolling twelve-month period, and the Customer may use or access the Thinkbox Marketplace outside the Home Country at any time.</p>
        <h3>4. Usage, Fees, Payments and Taxes.</h3>
        <p>(a) In order for Customer to access, use or run Software via the Thinkbox Portal to complete a Task, Customer must pre-purchase Usage (by the hour) of such Software (“Prepaid Usage”) for such Task via the Thinkbox Marketplace.</p>
        <p>(b) All fees and expenses (“Fees”) for Usage are not refundable in whole or in part, unless stated otherwise by Thinkbox in writing. Customer understands that the Fees in respect of Usage of Software may vary from product to product.</p>
        <p>(c) Once the Customer has exhausted the Prepaid Usage in respect of a Task, the Customer will only be able to have access to, or be able to use or run, the Software as required to complete such Task. The Usage of the Software to complete the Task in excess of the Prepaid Usage (the “Excess Usage”) may be metered by Thinkbox, in which case Thinkbox will render an invoice to the Customer for Fees for such Excess Usage, which shall be paid within thirty (30) days from the date of Thinkbox’s invoice. Any Fees or portion thereof which are not paid within thirty (30) days shall be charged daily interest at the rate of 24% per annum, from the date of the invoice until paid in full.</p>
        <p>(d) For so long as there are any Fees for Excess Usage which are unpaid by Customer (regardless of whether they have are overdue), the Customer will not have access to, or be able to use or run, the Software via the Thinkbox Portal unless and until such Fees for Excess Usage are paid (plus interest, if any) and additional Usage of such Software has been purchased.</p>
        <p>(e) All Fees are in U.S. Dollars and are exclusive of all sales taxes. Customer shall pay and indemnify Thinkbox for, and hold Thinkbox harmless from, any sales, use, value-added, withholding, excise, import or export, or similar tax (excluding only taxes based on Thinkbox’s net income) or other duty or fee and any penalties or interest associated with any taxes imposed by any governmental authority with respect to any payment to be made by Customer to Thinkbox under this Agreement. Thinkbox reserves the right to change the Fees at any time without notice; provided, however, that such change will not affect the Fees for Usage already purchased and paid in full. Thinkbox reserves the right to cancel the grant of use of the Thinkbox Portal hereunder if any funds are insufficient or the credit card information provided by Customer is not valid. Customer acknowledges that payments may be processed in cooperation with third party payment service providers.</p>
        <p>(f) Any refunds of Prepaid Usage shall be dealt with in accordance with the Company’s refund policy from time to time.</p>
        <p>(g) Trade Compliance. In connection with this Agreement, each party will comply with all applicable import, re-import, sanctions, anti-boycott, export, and re-export control laws and regulations, including all such laws and regulations that apply to a U.S. company, such as the Export Administration Regulations, the International Traffic in Arms Regulations, and economic sanctions programs implemented by the Office of Foreign Assets Control. For clarity, you are solely responsible for compliance related to the manner in which you choose to use the Service Offerings, including your transfer and processing of Your Content, the provision of Your Content to End Users, and the AWS region in which any of the foregoing occur. You represent and warrant that you and your financial institutions, or any party that owns or controls you or your financial institutions, are not subject to sanctions or otherwise designated on any list of prohibited or restricted parties, including but not limited to the lists maintained by the United Nations Security Council, the U.S. Government (e.g., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the U.S. Department of Treasury, and the Entity List of the U.S. Department of Commerce), the European Union or its Member States, or other applicable government authority.</p>
        <h3>5. License of the Software.</h3>
        <p>(a) All right, title and ownership of the Software and all Intellectual Property Rights therein remains with the developer of such Software (which may be a third-party developer other than Thinkbox). Software that has been developed, and licensed to the Customer, by Thinkbox is referred to herein as a “Thinkbox Product” and Software that has been developed, and licensed to the Customer, by a third-party developer (the “Third-Party Licensor”) is referred to herein as a “Third-Party Product”.</p>
        <p>(b) Customer acknowledges that the Software made available through the Thinkbox Portal may only be used under license between the Customer and the developer of the Software, and that the use of the Software through the Thinkbox Marketplace will be subject to the terms and conditions of the end user license agreement applicable to such Software (the “EULA”).</p>
        <p>(c) Customer acknowledges and agrees that any license of a Third-Party Product is from the Third-Party Licensor and that Thinkbox is not party to the license between the Customer and the Third-Party Licensor with respect to the Third-Party Product. The Third-Party Licensor shall be solely responsible for the Third-Party Product, the content therein, any warranties (to the extent same have not been disclaimed) and any claims that the Customer may have relating to the Third-Party Product.</p>
        <h3>6. Third-Party Materials</h3>
        <p>Certain content, Software (including Third-Party Products) and services available via the Thinkbox Portal may include materials from third parties. Thinkbox may provide links to third-party websites as a convenience to the Customer. Thinkbox shall not be responsible for examining or evaluating the content or accuracy of any third party materials or websites, and Thinkbox does not warrant, and will not have any liability or responsibility for, any third-party materials or websites.</p>
        <h3>7. Storage.</h3>
        <p>Thinkbox will have no responsibility to store the content created by the Customer using the Software via the Thinkbox Portal (the “Content”). Customer shall be solely responsible for the Content, including its storage, maintenance, operating and compatibility in and with the cloud, and any third-party claims regarding same. Customer acknowledges and agrees that Thinkbox does not have control over the Content, including the transmission of same to a storage system, and that any such transmission is solely at Customer’s own risk, and Thinkbox shall not be responsible for any disclosure, modification or deletion of the Content resulting from such transmission, or from any user error or other improper use of the Software or the Thinkbox Portal.</p>
        <h3>8. Confidentiality and Privacy Policy.</h3>
        <p>(a) Thinkbox shall use reasonable efforts to protect information submitted by the Customer in connection with the Thinkbox Marketplace or the Thinkbox Portal, but the Customer acknowledges and agrees that the submission of information by the Customer is at its sole risk, and Thinkbox disclaims any and all liability to the Customer for any loss or liability relating to such information in any way.</p>
        <p>(b) The Customer acknowledges and agrees that Thinkbox may record, collect, use and store data regarding the Customer’s use of the Software, the Thinkbox Marketplace and/or the Thinkbox Portal, including, without limitation, Personal Information, the Customer’s IP address, Account information and Usage of the Software and/or the Thinkbox Marketplace (“Customer Data”), and the Customer, in consideration of the rights granted to it herein, hereby consents to same.</p>
        <p>(c) The Customer further agrees that Thinkbox may use, edit, consolidate, reproduce, sell and communicate, in any manner, non-identifiable information derived from the Customer Data (ex. aggregated and analytics information) for any legitimate business purpose, and hereby grants permission, and a royalty-free license, to Thinkbox to use, edit, consolidate, reproduce, sell and communicate the Customer Data accordingly.</p>
        <p>(d) Thinkbox undertakes to comply with the terms of its privacy policy found at&nbsp;<a href="http://www.thinkboxsoftware.com/terms-of-use">http://www.thinkboxsoftware.com/terms-of-use</a>&nbsp;and Customer agrees that Thinkbox may use and store any Personal Information of the Customer in such a manner as Thinkbox requires to perform its services.</p>
        <h3>9. Term.</h3>
        <p>The term of this Agreement is perpetual, provided however that Thinkbox has the right to terminate this Agreement immediately if Customer fails to comply with any of the terms of this Agreement or of any EULA or other agreement in respect of the Software. Upon termination of this Agreement, Thinkbox may, at its sole discretion, terminate the license to use any Thinkbox Product via the Thinkbox Portal granted to the Customer and/or deactivate the Account. Such termination shall not prejudice or affect the accrued rights or claims of Thinkbox, including for any unpaid Fees.</p>
        <h3>10. Warranties/Exclusive Remedies.</h3>
        <p>Thinkbox warrants for Customer’s benefit alone that the Thinkbox Portal will operate substantially in accordance with the functional specifications in Thinkbox’s documentation. If there is any breach of this warranty by Thinkbox, Thinkbox shall repair the defect that does not meet this limited warranty within a reasonable time. The foregoing warranty applies only to failures in operation of the Thinkbox Portal that are reproducible in standalone form and does not apply if the defect is caused due to problems inherent in the use of the internet and/or electronic communications. Access to the Thinkbox Portal, and all Software delivered to the Customer via the Thinkbox Portal, is granted to the Customer “AS IS” and “AS AVAILABLE”, and Thinkbox disclaims any warranty or liability obligations to Customer of any kind. Thinkbox does not warrant the performance or results Customer may obtain by using the Thinkbox Portal, Software or documentation. Thinkbox does not make any warranties, express or implied, as to non-infringement of third party rights, merchantability, or fitness for any particular purpose of the Thinkbox Portal or the Software (including any Thinkbox Products). The foregoing states Customer’s sole and exclusive remedy and Thinkbox’s sole and exclusive obligation and liability for any breach of this limited warranty. This limited warranty gives Customer specific legal rights. Customer may have others, which vary from jurisdiction to jurisdiction.</p>
        <p>Without limitation to the foregoing, the Customer acknowledges and agrees that a high speed Internet connection is required for proper transmission of, and access to, the Thinkbox Marketplace and the Software and that the Customer is responsible for procuring and maintaining the network connections that connect the Customer to the Software. Thinkbox shall have no responsibility to notify the Customer of any compromises of data transmitted across computer networks or telecommunications facilities (including the Internet) which are not owned, operated or controlled by Thinkbox, and Thinkbox shall not be liable for any internet outages or other service outages, improper shutdown of rendering hardware, user error or other improper use of the Software or the Thinkbox Portal which may interrupt Usage or otherwise affect the Customer’s use of the Thinkbox Portal or the Software.</p>
        <h3>11. Support.</h3>
        <p>Thinkbox will provide free support regarding the functionality of the Thinkbox Portal for the duration of this Agreement. Thinkbox shall not be responsible to provide any support for any Software made available via the Thinkbox Portal, other than Thinkbox Products, in which case support shall be governed by the applicable EULA for such product. Support relating to any Third-Party Software shall be the responsibility of the Third-Party Licensor, in accordance with the applicable EULA for such product.</p>
        <h3>12. Limitations of Liability.&nbsp;</h3>
        <p>Thinkbox shall not be liable for any indirect, incidental, special or consequential damages, or damages for loss of profits, revenue, data or data use incurred by Customer or any third party, whether in an action in contract or tort (including without limitation, negligence and strict liability), by statute, or other legal theory even if Thinkbox has been advised of the possibility of such damages. Thinkbox’s liability for damages hereunder shall in no event exceed USD$100. The parties agree to the allocation of liability risk, which is set forth in this section. The foregoing limitations will apply even if the above stated warranty fails of its essential purposes. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, and so the above limitation may not apply in respect of those jurisdictions only.</p>
        <h3>13. General.</h3>
        <p>The division of this Agreement into sections and the insertion of headings are for convenience only and shall not affect the interpretation of this Agreement. This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements, written or oral. This Agreement shall be construed, interpreted and enforced in accordance with the laws of Manitoba, Canada and each party irrevocably and unconditionally submits to the non-exclusive jurisdiction of the courts of such province and all courts competent to hear appeals therefrom. Customer may not assign or transfer this Agreement without the written consent of Thinkbox. THIS AGREEMENT MAY NOT BE MODIFIED BY THE CUSTOMER BUT MAY BE MODIFIED BY THINKBOX FROM TIME TO TIME, NOTICE OF WHICH WILL BE PROVIDED TO THE CUSTOMER AND CONTINUED USE OF THE THINKBOX MARKETPLACE OR THINKBOX PORTAL SHALL BE CONSIDERED ACCEPTANCE OF SUCH MODIFIED AGREEMENT. The parties shall not be liable for any failure to perform their respective obligations under this Agreement where such failure is due to causes beyond their reasonable control (except for any payment of money hereunder). Customer will comply with all applicable laws and regulations in its use of the Thinkbox Marketplace, the Thinkbox Portal, the Software or other technical data delivered by Thinkbox under this Agreement and Customer agrees to comply strictly with all applicable export or import laws and regulations after delivery to Customer. All terms and conditions, which by their nature should survive the termination or expiration of this Agreement, shall survive. Invalidity of any provision of this Agreement shall not affect the validity of the remaining provisions of this Agreement. No waiver of a provision of this Agreement shall constitute a continuing waiver unless otherwise agreed to in writing.</p>
      </div>
  );
}

export default TermsOfUse;
