import React from 'react';
import '../App.css';
import CollectionBox from './CollectionBox';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
/**
 * Component that displays the list of Product Collections.
 * Ex. 3ds Max, Cinema4D
 *
 * @param {Function} setSelectedCollection function for setting the selected
 * collection
 * @param {Array<object>} collections array of objects containing collection
 * information (title, description, image, id)
 * @return {Component}
 */
function CollectionList({setSelectedCollection, collections}) {
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">Usage Based Licensing</h1>
      <p className="content-container-text">
        Click on a product below to purchase Usage Based licensing. Please
        review the{' '}
        <Link to="/requirements" className="clickable" >
          Usage Based Licensing Requirements{' '}
        </Link>
        prior to making a purchase.
      </p>
      <div className="license-box-view">
        {collections ? collections.map((collection, index) => {
          return (
            <CollectionBox
              collection={collection}
              setSelectedCollection={setSelectedCollection}
              key={index}
            />
          );
        }): <div/>}
      </div>
    </div>
  );
}

CollectionList.propTypes = {
  setSelectedCollection: PropTypes.func,
  collections: PropTypes.array,
};

export default CollectionList;
