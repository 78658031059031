import React from 'react';
import '../App.css';

/**
 * Component for the Floating Licenses section of the website.
 * @return {Component}
 */
function FloatingLicenses() {
  document.title = 'Floating Licenses';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">Floating Licenses</h1>
      <p className="content-container-text">
        Traditional floating licenses are still available for all Thinkbox
        products, and can be purchased by contacting{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://pages.awscloud.com/ThinkBoxContactUs.html">
          Thinkbox Sales
        </a>
        . Prices for floating licenses can be found on our{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/product-pricing">
          Product Pricing
        </a>{' '}
        page.
        Temporary floating licenses for testing are also still available.{' '}
        <br />
        <br />
        Note that Usage Based Licensing (which can be purchased from the
        Thinkbox Marketplace) can be used in conjunction with floating licenses,
        which is useful for temporary increases in render nodes (cloud burst
        compute, rentals, artist machines overnight, etc).
      </p>
    </div>
  );
}

export default FloatingLicenses;
