import React, {Component, useState} from 'react';
import '../App.css';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Modal from '../common/Modal';
import {backendURL} from '../common/urls';
import {useHistory, useLocation} from 'react-router-dom';
/**
 * Component that represents the Password recovery view.
 * @return {Component}
 */
function ResetPassword() {
  document.title = 'Reset Password';
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetPasswordURL = `${backendURL}/resetPassword`;
  const history = useHistory();
  const urlParams = useQuery();
  /**
   * Parses search parameters using useLocation hook.
   * @return {Object}
   */
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  /**
   * Utilize password recovery endpoint.
   * @param {event} e
   */
  async function handlePasswordReset(e) {
    e.preventDefault();
    const id = urlParams.get('id');
    const resetToken = urlParams.get('token');
    if (!id || !resetToken) {
      setErrorMessage('Invalid password reset link');
      return;
    }
    // A request is already in flight, do nothing.
    if (loading) {
      return;
    }
    // Input sanitation
    const [invalid, message] = isPasswordInvalid();
    if (invalid) {
      setErrorMessage(message);
      return;
    }

    setLoading(true);
    try {
      const recoveryEmailSent = await resetPassword(id, resetToken, password);
      if (!recoveryEmailSent) {
        setErrorMessage('Failed to reset password.');
      } else {
        setDisplayModal(true);
        setErrorMessage('');
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }


  /**
   * Reset the customers password
   * @param {string} id customer id
   * @param {string} resetToken password reset token
   * @param {string} password new password
   * @return {boolean} whether or not a password reset email was sent.
   */
  async function resetPassword(id, resetToken, password) {
    const response = await fetch(resetPasswordURL, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        resetToken: resetToken,
        password: password,
      }),
    });
      // we expect response to have 200 status code.
    if (response.status !== 200) {
      return false;
    }
    // Success
    return true;
  }


  /**
   * Returns an array whose first element is a boolean value indicating
   * whether or not the password is valid and whose second element is a message
   * indicating why the password is invalid.
   * @return {Array}
   */
  function isPasswordInvalid() {
    if (!password) {
      return [true, 'New password is empty'];
    }
    if (!confirmPassword) {
      return [true, 'Confirm new password is empty'];
    }
    if (password != confirmPassword) {
      return [true, 'Passwords do not match'];
    }
    if (password.length < 8) {
      return [true, 'Password must be at least 8 characters.'];
    }
    if (password.length > 128) {
      return [true, 'Password must be less than 128 characters.'];
    }
    if (! new RegExp('[a-z]').test(password)) {
      return [true, 'Password does not contain a lower case character.'];
    }
    if (! new RegExp('[A-Z]').test(password)) {
      return [true, 'Password does not contain an upper case character.'];
    }
    if (! new RegExp('[0-9]').test(password)) {
      return [true, 'Password does not contain a number.'];
    }
    if (! new RegExp('[^a-zA-Z0-9]').test(password)) {
      return [true, 'Password does not contain a special character.'];
    }
    if (new RegExp('[^ -~]').test(password)) {
      return [true, 'Password contains an invalid character.'];
    }
    return [false, ''];
  }

  /**
   * Close display modal and navigate to sign in page
   */
  function closeModal() {
    setDisplayModal(false);
    history.push('sign-in');
  }

  return (
    <div className="content-container">
      <div className="spinner-container" style={{display: loading ? 'block' : 'none'}}>
        <Spinner className="spinner" variant="inverted" size="big"/>
      </div>
      <Modal
        visible={displayModal}
        text={'Your password has been reset'}
        close={() => closeModal()}
      />
      <h1 className="content-container-section-name">Reset Password</h1>
      <form onSubmit={(e) => handlePasswordReset(e)} className="credentials-form">
        <div>
          <label className="form-label">New Password</label>
          <input
            className="form-field"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label className="form-label">Confirm New Password</label>
          <input
            className="form-field"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <input className="authentication-button" type="submit" value="Reset Password"></input>
      </form>
      <p className="error-text">{errorMessage}</p>
    </div>
  );
}


export default ResetPassword;
