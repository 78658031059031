import React, {useContext, useState} from 'react';
import {backendURL} from './urls';

const AccountContext = React.createContext();

/**
 * Context provider whose value is an array containing customer account details
 * and a function for setting the account details.
 * @param {any} props
 * @return {Component} Context Provider
 */
function AccountProvider(props) {
  const [accountDetails, setAccountDetails] = useState(null);
  const [pendingOrders, setPendingOrders] = useState(null);
  const values = {
    'accountDetails': accountDetails,
    'setAccountDetails': setAccountDetails,
    'pendingOrders': pendingOrders,
    'setPendingOrders': setPendingOrders,
  };
  return <AccountContext.Provider
    value={values}
    {...props}/>;
}

/**
 * Returns an array whose first element is an object containing account details
 * and whose second element is a function for setting the account details
 * object.
 *
 * Can only be used from a component that is within an AccountProvider.
 * Example:
 * <AccountProvider>
 *  <TestComponent/>
 * <AccountProvider>
 *
 * @return {Array<object, Function>}
 */
function useAccount() {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
}

/**
 * Returns whether or not an account state is signed in.
 * @param {Object} accountDetails
 * @return {boolean} whether or not the user is signed in
 */
function isSignedIn(accountDetails) {
  if (accountDetails === undefined || accountDetails == null) {
    return false;
  }
  return true;
}

/**
   * Retrieve the users account info using their credentials.
   * @param {string} credentials
   * @throws {Error} If status code is not 200
   * @return {object} Object containing users account details
   */
async function getAccountDetails() {
  const accountDetailsUrl = `${backendURL}/accountDetails`;
  const response = await fetch(accountDetailsUrl, {
    method: 'GET',
    credentials: 'include',
  });
  const body = await response.json();
  if (!response.ok) {
    throw new Error(body['message']);
  } else {
    return body;
  }
}

/**
   * Retrieve the users account info using their credentials.
   * @param {string} credentials
   * @throws {Error} If status code is not 200
   * @return {object} Object containing users access token
   */
async function getOpenDraftOrders() {
  const openDraftOrdersUrl = `${backendURL}/openDraftOrders`;
  const response = await fetch(openDraftOrdersUrl, {
    method: 'GET',
    credentials: 'include',
  });
  const body = await response.json();
  if (!response.ok) {
    throw new Error(body['message']);
  } else {
    return body;
  }
}

/**
 * Returns the users account info and a list of their pending orders.
 * Utilizes javascript Promise.all to send http requests concurrently.
 * @return {List} A list whose first element is an object containing the
 * users account details and whose second element is the a list of the users
 * pending orders.
 */
async function getAccountDetailsAndPendingOrders() {
  const [accountDetails, pendingOrders] = await Promise.all(
      [getAccountDetails(), getOpenDraftOrders()]);
  return [accountDetails, pendingOrders];
}


export {AccountProvider,
  useAccount,
  isSignedIn,
  getAccountDetailsAndPendingOrders};
