/* eslint max-len: ["error", { "ignorePattern": "<*>" }]*/
import React, {Component} from 'react';
import {Link, useLocation} from 'react-router-dom';

/**
 * Component for the Eula agreemment of a specific autodesk product.
 * @return {Component}
 */
function AutodeskEula() {
  document.title = 'Autodesk EULA';
  /**
   * Parses search parameters using useLocation hook.
   * @return {Object}
   */
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const product = query.get('product');
  return (
    <div className="terms-of-use">
      <h1>Terms and conditions for {product ? product : 'Autodesk'}</h1>
      <h3 ><u>AUTODESK TERMS</u></h3>
      <p><u>Autodesk Products</u>. Customer's use of the Autodesk products, which may include associated materials or documentation (individually and collectively, "Autodesk Products"), provided by Thinkbox in conjunction with the Thinkbox Marketplace and Thinkbox Platform is subject to the Thinkbox Marketplace Terms of Service and other terms provided by Thinkbox at <Link to="/terms-of-use" className="clickable-link">Terms of Use</Link> (the “Agreement”) and the following additional terms and conditions.</p>
      <ol>
        <li>
          <strong><u>Additional Terms.</u></strong> Customer's right to use the Autodesk Products or functionality of the Autodesk Products is subject to the terms of the Agreement, and to Customer's understanding of, compliance with, and consent to the following terms and conditions (“Additional Terms”), which Thinkbox does not have authority to vary, alter, or amend. By accessing or using the Autodesk Products, Customer (i) agrees to be bound by these Additional Terms, and (ii) is solely responsible for any content and data that Customer inputs in connection with Customer’s access to or use of the Autodesk Products. To the extent of any conflict between these Additional Terms and (a) the Agreement, or (b) any Autodesk software license agreement that may accompany the Autodesk Products provided by Thinkbox, these Additional Terms shall control solely with respect to the Autodesk Products.</li>
        <li>
          <strong><u>Ownership of Autodesk Products.</u></strong> The Autodesk Products are licensed to Thinkbox from Autodesk, Inc. and/or its subsidiaries and affiliates (collectively "Autodesk"). The Autodesk Products are the confidential information of Autodesk and its suppliers, and Customer agrees that under no circumstances may Customer disclose the Autodesk Products to any third party. Title to and ownership of the intellectual property rights associated with the Autodesk Products and any copies remain with Autodesk and its suppliers. Customer may not remove, modify, or obscure any copyright, trademark or other proprietary rights notices that are contained in or on the Autodesk Products.</li>
        <li>
          <strong><u>Prohibited Activities.</u></strong> Customer may not rent, lease, lend, pledge, or directly or indirectly transfer or distribute the Autodesk Products to any third party, and may not permit any third party to have access to and/or use the functionality of the Autodesk Products except for the sole purpose of accessing the functionality of the Autodesk Products in the form of software services in accordance with the terms of these Additional Terms and the Agreement. Customer may not alter, modify, translate, adapt, arrange, or create derivative works based on the Autodesk Products for any purpose, or reverse engineer, disassemble or decompile the Autodesk Products. Without limiting the foregoing, Customer may not integrate the Autodesk Products into another application, even if such application has material value independent of the Autodesk Products. Customer shall not copy the Autodesk Products except as necessary to access Thinkbox’s service within the scope of Customer’s authorized use.</li>
        <li>
          <strong><u>No Warranties, Liabilities, or Remedies by Autodesk.</u></strong>&nbsp;TO THE MAXIMUM EXTENT PERMITTED BY LAW, AUTODESK PRODUCTS AND FUNCTIONALITY ARE PROVIDED “AS IS” WITH ALL FAULTS, AND AUTODESK DISCLAIMS ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, LACK OF VIRUSES, ACCURACY OR COMPLETENESS OF RESPONSES OR RESULTS, LACK OF NEGLIGENCE, TITLE, QUIET ENJOYMENT, QUIET POSSESSION, CORRESPONDENCE TO DESCRIPTION OR NON-INFRINGEMENT, WITH REGARD TO THE AUTODESK PRODUCTS OR THEIR FUNCTIONALITY. Without limiting the foregoing, (i) Autodesk does not warrant that the operation of the Autodesk Products will be uninterrupted or error free; (ii) the entire risk as to the quality of or arising out of access to or use of the Autodesk Products remains with &nbsp;Customer, as applicable; and (iii) in no event shall Autodesk or its suppliers be liable in any way for indirect, special or consequential damages of any nature, including without limitation, lost business profits, or liability or injury to third persons, whether foreseeable or not, regardless of whether Autodesk or its suppliers have been advised of the possibility of such damages.</li>
        <li>
          <strong><u></u></strong> Customer retains copyright and any other rights it already holds in its own content and data that it uses in relation to the Autodesk Products, and Customer is responsible for all content and data (or other material, if any) that it uploads, posts, emails or otherwise transmits in using the Autodesk Products or their functionality. By submitting, posting or displaying content and/or data through the Autodesk Products, Customer grants Thinkbox and Autodesk a perpetual, irrevocable, worldwide, royalty-free and non-exclusive to reproduce, adapt and modify, perform and display (publicly or otherwise), transmit and distribute such content and/or data for the purpose of enabling Thinkbox to provide the Autodesk Products or their functionality.</li>
        <li>
          <strong><u>Feedback</u></strong><strong>.</strong> Customer hereby agrees that any Feedback provided by Customer to Thinkbox that relates to the Autodesk Products may be shared with Autodesk. Autodesk will have (and Customer grants) a royalty-free, worldwide, irrevocable, perpetual license to Autodesk use and incorporate any suggestions, feedback, improvement requests or other recommendations Customer or its users provide, relating to the Autodesk Products (“Feedback”).</li>
        <li>
          <strong><u>Autodesk Product Support.</u></strong> Autodesk, its affiliates and subsidiaries do not provide any support for the Autodesk.</li>
        <li>
          <strong><u>Provision of Information to Autodesk.</u></strong> Customer acknowledges that Thinkbox may provide Customer’s city, state and country to Autodesk using a unique anonymous identifier. To the extent necessary, Customer hereby consents for its personal data to be transferred to, collected, stored, used and otherwise processed by Autodesk and its affiliates and its and their contractors (including third parties that provide services to or for Autodesk and its affiliates) as contemplated in this section.</li>
      </ol>
    </div>
  );
}


export default AutodeskEula;
