import '../App.css';
import React, {useState, useEffect} from 'react';


function AddNewLine(text, outputArray){
  var brTextSplit = text.split("<br>")
    for(var j=0; j<brTextSplit.length-1; j++){
      outputArray.push(brTextSplit[j])
      outputArray.push(<br></br>)
      outputArray.push(<br></br>)
    }
    outputArray.push(brTextSplit[brTextSplit.length-1])
}

/**
 * Component for the header at the top of the page.
 * @return {Component}
 */
function Notice({text,link,word}) {
  var textSplit = text.split("<here>");
  var output = [];
  for(var i=0; i<textSplit.length-1; i++){
    AddNewLine(textSplit[i],output);
    output.push(<a href={link[i]}>{word[i]}</a>)
  }
  AddNewLine(textSplit[textSplit.length-1],output);

  return (
    <div className="notice-container">
        {output}
    </div>
  );
}


export default Notice;
