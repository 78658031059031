import React, {useContext, useState} from 'react';

const CollectionContext = React.createContext();

/**
 * Context provider whose value is an array containing the currently selected
 * collection and a function for setting the currently selected collection.
 * @param {any} props
 * @return {Component} Context Provider
 */
function CollectionProvider(props) {
  const [collection, setCollection] = useState(null);
  return <CollectionContext.Provider
    value={[collection, setCollection]}
    {...props}/>;
}

/**
 * Returns an array whose first element is an object with information on the
 * currently selected collection (title, id, image), second element is a
 * function for setting that collection.
 *
 * Can only be used from a component that is within a CollectionProvider.
 * Example:
 * <CollectionProvider>
 *  <TestComponent/>
 * <CollectionProvider>
 *
 * @return {Array<object, Function>}
 */
function useCollection() {
  const context = useContext(CollectionContext);
  if (!context) {
    throw new Error('useCollection must be used within a CollectionProvider');
  }
  return context;
}

export {CollectionProvider, useCollection};
